
import { Component, Vue } from "vue-property-decorator";
import UserManagementService from '@/services/user-management'
import PopUp from "@/components/PopUp.vue";
import utils from "@/utils/utils"
@Component({
  components: {
    PopUp
  },
})
export default class Employee extends Vue {
  email = '';
  error: any = "";
  modalShow = false;
  modalMessage = "";
  modalType = "";
  modalErrorMessage = [];
  errorMessages: any = [];
  saveData = true;

  closeModal() {
    this.modalShow = false;
    this.modalMessage = "";
    this.modalType = "";
    this.errorMessages = [];
  }
  showModal(message: string, type: "success" | "error") {
    this.modalShow = true;
    this.modalMessage = message;
    this.modalType = type;
  }
  showModalErrors(message: [], type: "errorArray") {
    this.modalShow = true;
    this.modalErrorMessage = message;
    this.modalType = type;
  }
  public emailObject: any = {}
  forgetPasswordEmail() {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const error_list = utils.getEmptyKeys(this.emailObject, ['email'])
    if (error_list.length > 0) {
      this.error = utils.generateErrorMessage(error_list)
    }
    else if (!emailRegex.test(this.emailObject.email)) {
      this.error = 'Invalid email address';
      return;
    }
    else {
      const emailObject = {
        email: this.emailObject.email
      }
      this.error = "";
      UserManagementService.forget_Password(emailObject)
        .then((res) => {
          this.resetEmail();
          this.showModal("Email Sent Successfully", "success")
        })
        .catch((e) => {
          console.log(e);
          this.showModal("Email Not Sent Something Wrong", "error");
        });
    }
  };
  resetEmail() {
    this.emailObject.email = '';
    this.error = "";
  }
}
